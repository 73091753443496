footer{
    background-color: #fff;
    border-top: 1px solid #eee;
    padding: 20px 0;
}


footer a{
    display: block;
    padding: 10px;
    text-decoration: none;
}

footer a:hover{
    text-decoration: underline;
}

.small-logo{
    height: 50px;
    margin: 0 auto;
    display: block;
}
