body{
    background-color: #f5f5f5;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container{
    background-color: #fff;
}